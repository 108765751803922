<template>
  <div>
    <div class="card">
      <div class="bg-blue-light px-1 rounded pb-2">
        <TitleButton
            class="mt-1"
            btnTitle="List"
            :showBtn="true"
            :showAddNew="false"
            :title="(fd6Id === '') ? 'Add New FD6' : 'Edit FD6'"
            @onClickCloseButton="navigateToListPage"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">FD6 Name</label>
              <input type="text" class="form-control" v-model="formData.name">
            </div>
            <div class="col-sm-4">
              <label class="align-middle">FD6 Code</label>
              <input type="text" class="form-control" v-model="formData.code">
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Create Date</label>
              <input type="date" class="form-control" v-model="formData.date">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Start Date</label>
              <input type="date" class="form-control" v-model="formData.start_date">
            </div>
            <div class="col-sm-4">
              <label class="align-middle">End Date</label>
              <input type="date" class="form-control" v-model="formData.end_date">
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Party</label>
              <v-select
                  placeholder="Select Project"
                  v-model="formData.party_id"
                  :options="parties"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Interval</label>
              <v-select
                placeholder="Select Interval"
                v-model="formData.interval"
                :options="intervals"
                label="name"
                :reduce="name => name.id"
              />
              <span v-if="errors.hasOwnProperty('interval')" class="invalid-feedback d-block qcont">{{ errors.interval[0] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="px-1 rounded pb-2" v-if="formData.id">
        <TitleButton
            class="mt-1"
            btnTitle="Add New"
            :showBtn="false"
            :showAddNew="true"
            title="FD6 Accounts"
            @onClickAddNewButton="onClickNewAccountGroupModal"
        >
        </TitleButton>

        <div class="row gy-1">
          <section id="basic-tabs-components">
            <div class="row match-height">
              <div class="col-12">
                <ul class="nav nav-tabs" role="tablist">
                  <li
                      class="nav-item"
                      :class="`${accountType == i ? 'active' : ''}`"
                      v-for="(tab, i) in tabs"
                      :key="i"
                      @click="onClickTab(i, tab)"
                      type="button"
                  >
                    <a :class="`${accountType == i ? 'active' : ''}`" v-if="i === 'activity'" class="nav-link" data-bs-toggle="tab"
                       aria-controls="home" role="tab" aria-selected="true">{{ tab }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>

      </div>

      <div class="card-body">
        <div class="row d-flex flex-wrap justify-content-between align-items-center">
          <TreeBrowser
              :style="{opacity: chartLoading ? .5 : 1}"
              v-for="(item, i) in projectAccountList"
              :key="i"
              :node="item"
          />
        </div>
      </div>

      <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
                class="btn btn-primary">
          Save
        </button>
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                class="btn btn-primary">Save & New
        </button>
        <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
      </div>
      <GlobalLoader/>
    </div>

    <AccountGroupModal
        :modal-title="accountTypeName"
        ref="accountGroup"
        :query="getQuery()"
        :project-account-level="projectAccountLevel"
        :types="homeContent.project_account_type"
        :account-type="accountType"
        :project-id="formData.id"
        :showParent="showParent"
        :form="accountGroupForm"
        :accounts="allAccountGroups"
        :ngoDefaultAccountTypes="ngoDefaultAccountTypes"
        :isFD6="true"
        @onAccountSave="handleProjectAccountSubmit"
        @onCloseGroupModal="resetAccountModalForm"
    />

  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref} from 'vue';
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton';
import handleFD6s from "@/services/modules/procurement/fd6";
import handleInventory from "@/services/modules/inventory";
import TreeBrowser from "@/components/molecule/procurement/fd6/TreeBrowser";
import AccountGroupModal from "@/components/molecule/procurement/fd6/AccountGroupModal";

const router = useRouter();
const route = useRoute();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const emitter = inject('emitter');

let accountGroup = ref(null);
let loader = ref(false);
let saveButtonLoader = ref(false);
let saveNewButtonLoader = ref(false);
let parties = ref([]);
let accountType = ref('activity');
let accountTypeName = ref('Activity');
let projectAccountList = ref([]);
let homeContent = ref({});
let showParent = ref(false);
let allAccountGroups = ref([]);
let projectAccountLevel = ref('group');
let errors = ref({});

let {
  fetchHome,
  chartLoading
} = handleInventory();

let {
  fetchParties,
  createNewFD6,
  fetchFD6Form,
  fetchFD6AccountTree,
  createFD6AccountGroup,
  createFD6Account,
  updateFD6AccountGroup,
  updateFD6Account,
  fetchFD6Groups,
  fetchFD6Account,
  updateFD6,
} = handleFD6s();

let formData = ref({
  name: null,
  code: null,
  date: null,
  start_date: null,
  end_date: null,
  party_id: null,
  interval: null,
})

let accountGroupForm = ref({
  linked_item_id: "",
  linked_account_id: "",
  name: "",
  code: "",
  description: "",
  project_account_id: "",
  project_account_type: "",
});

function navigateToListPage() {
  route.params.fd6Id = null;
  router.push({name: `fd6-list`, params: route.params, query: route.query});
}

function handleSubmit(redirect = false) {
  if (redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }

  let res = null;
  if (fd6Id.value) {

    res = updateFD6(fd6Id.value, getQuery(), formData.value);
  } else {
    res = createNewFD6(getQuery(), formData.value);
  }

  res.then(res => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false

    if (res.status) {
      showSuccess(res.message)
      if (redirect) navigateToListPage()
      resetForm()
    }
    if (!res.status) {
      showError(res.message)
    }
  })
  .catch(err => {
    errors.value = err.response.data.errors;
    showError(err.response.data.message);
    saveButtonLoader.value = false;
    saveNewButtonLoader.value = false;
  })
}

function showAddAccountModal() {
  accountGroup.value?.toggleModal()
}

function onClickTab(tab, name) {
  if (tab === "activity"){
    accountType.value = tab;
    accountTypeName.value = name;
    projectAccountList.value = [];
    getAccountTree();
    getProjectGroups();
  }
}

function getQuery() {
  let query = `?company_id=${route.params.companyId}`;

  if (accountType.value) {
    query += `&account_type=${accountType.value.toLowerCase()}`;
  }
  return query;
}

function resetForm() {
  formData.value = {
    name: null,
    code: null,
    date: null,
    business_id: null,
    start_date: null,
    end_date: null,
    party_id: null,
    project_teams: [],
  }
}

function resetAccountModalForm() {
  accountGroupForm.value = {
    linked_item_id: "",
    linked_account_id: "",
    name: "",
    code: "",
    description: "",
    project_account_id: "",
    project_account_type: "",
  };
  showParent.value = false;
  projectAccountLevel.value = 'group';
}

async function getAccountTree() {
  const res = await fetchFD6AccountTree(fd6Id.value, getQuery());

  if (res.status) {
    projectAccountList.value = res.data;
  }
}

async function getProjectGroups() {
  const res = await fetchFD6Groups(fd6Id.value, getQuery());
  if (res.data) {
    allAccountGroups.value = res.data;
  }
}

function onClickNewAccountGroupModal() {
  showAddAccountModal();
  projectAccountLevel.value = 'group';
}

async function handleAccountSave() {
  if (accountGroupForm.value.id) {
    return await updateFD6Account(accountGroupForm.value.id, getQuery(), accountGroupForm.value);
  }

  return await createFD6Account(getQuery(), accountGroupForm.value);
}

async function handleGroupSave() {
  if (accountGroupForm.value.id) {
    return await updateFD6AccountGroup(accountGroupForm.value.id, getQuery(), accountGroupForm.value);
  }
  
  return await createFD6AccountGroup(getQuery(), accountGroupForm.value);
}

async function handleProjectAccountSubmit() {
  let res = null;
  accountGroupForm.value.project_id = fd6Id;
  if (projectAccountLevel.value == 'group') {
    res = await handleGroupSave();
  } else {
    res = await handleAccountSave();
  }

  if (!res.status) {
    showError(res.message);
  } else {
    showSuccess(res.message);
    resetAccountModalForm();
    showAddAccountModal();
    await getAccountTree();
    await getProjectGroups();
  }
}

const tabs = computed(() => {
  return homeContent.value.project_account_type;
})

const fd6Id = computed(() => {
  return route.params.fd6Id ? route.params.fd6Id : '';
});

const intervals = computed(() => {
  let intervals = [];
  if(homeContent.value.hasOwnProperty('project_intervals')) {
    Object.keys(homeContent.value.project_intervals).forEach( id => {
      intervals.push({
        id: id,
        name: homeContent.value.project_intervals[id],
      })
    })
  }
  return intervals;
})

const ngoDefaultAccountTypes = computed(() => {
  let ngoDefaultAccountTypes = [];
  if(homeContent.value.hasOwnProperty('ngo_default_account_types')) {
    Object.keys(homeContent.value.ngo_default_account_types).forEach( id => {
      ngoDefaultAccountTypes.push({
        id: id,
        name: homeContent.value.ngo_default_account_types[id],
      })
    })
  }
  return ngoDefaultAccountTypes;
})

emitter.on('onClickAddGroup', (event) => {
  accountGroupForm.value.project_account_id = event.id;
  accountGroupForm.value.project_account_type = event.project_account_type;
  showParent.value = true;
  projectAccountLevel.value = 'group';
  accountGroup.value?.toggleModal()
});

emitter.on('onGroupUpdate', async ({data}) => {
  const res = await fetchFD6Account(data.id, getQuery());
  if (res.status) {
    accountGroupForm.value = res.data;
  }

  if (data.group_level != 1) {
    showParent.value = true;
  }
  projectAccountLevel.value = 'group';
  showAddAccountModal();
});

emitter.on('onClickAddAccount', (event) => {
  accountGroupForm.value.project_account_id = event.id
  accountGroupForm.value.project_account_type = event.project_account_type
  showParent.value = true
  projectAccountLevel.value = 'account';
  accountGroup.value?.toggleModal()
})

emitter.on('onAccountUpdate', async ({data}) => {
  const res = await fetchFD6Account(data.id, getQuery());
  if (res.status) {
    accountGroupForm.value = res.data;
  }
  showParent.value = true
  projectAccountLevel.value = 'account';
  showAddAccountModal();
})

onMounted(async () => {
  loader.value = true;
  const partyRes = fetchParties(getQuery());
  const homeRes = fetchHome();

  await Promise.all([
    homeRes.then(res => {
      if (res.data) {
        homeContent.value = res.data;
      }
    }),
    partyRes.then(res => {
      if (res.data) {
        parties.value = res.data;
      }
    }),
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  });

  if (fd6Id.value) {
    const res = await fetchFD6Form(fd6Id.value, getQuery());
    if (res.status) {
      formData.value = res.data;
      await getAccountTree();
    }
    await getProjectGroups();
  }
});

</script>